import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { useState } from 'react';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import Text, { TextType } from '../../Text/Text';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';
import ChevronUp from 'wix-ui-icons-common/on-stage/ChevronUp';
import { CancellationPolicyDataHooks } from './dataHooks';
import { classes } from './CancellationPolicy.st.css';

export interface CancellationPolicyProps {
  policy?: string;
}

const CancellationPolicy: React.FC<CancellationPolicyProps> = ({ policy }) => {
  const { t } = useTranslation();
  const [shouldShowPolicy, setShouldShowPolicy] = useState<boolean>(false);
  if (!policy) {
    return null;
  }
  return (
    <div className={classes.root}>
      <TextButton
        priority={TextButtonPriority.secondary}
        suffixIcon={shouldShowPolicy ? <ChevronUp /> : <ChevronDown />}
        data-hook={CancellationPolicyDataHooks.TEXT_BUTTON}
        onClick={() => setShouldShowPolicy(!shouldShowPolicy)}
      >
        {t('app.booking-details.cancellation-policy')}
      </TextButton>
      {shouldShowPolicy && (
        <div className={classes.text}>
          <Text
            type={TextType.Secondary}
            data-hook={CancellationPolicyDataHooks.TEXT}
          >
            {policy}
          </Text>
        </div>
      )}
    </div>
  );
};

export default CancellationPolicy;
